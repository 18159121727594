import React, { ReactElement } from 'react'
import { Box } from '@mui/material'
import useGlobalText from '@components/system/hooks/useGlobalText'
import Button from '@components/core/ui/button'

interface IStickyJobsButton {
  linkTo?: LAW.Props.LinkTo
  externalUrl?: string
}

export default function StickyJobsButton({
  linkTo,
  externalUrl,
}: IStickyJobsButton): ReactElement | null {
  if (!linkTo && !externalUrl) return null

  const { getText } = useGlobalText()
  return (
    <Box
      sx={{
        width: '100%',
        position: 'sticky',
        bottom: '30%',
        display: 'flex',
        justifyContent: 'flex-end',
        pointerEvents: 'none',
      }}
    >
      <Box
        sx={(theme) => ({
          pointerEvents: 'all',
          marginRight: { xs: '-53px', lg: '-67px' },
          transform: {
            xs: 'rotate(-90deg) ',
            lg: 'rotate(-90deg) ',
          },
          zIndex: 9999,
          borderTopLeftRadius: { xs: theme.spacing(6), lg: theme.spacing(8) },
          borderTopRightRadius: {
            xs: theme.spacing(6),
            lg: theme.spacing(8),
          },
          backgroundColor: theme.palette.primary.main,
          transition: 'all 0.2s ease-in-out 0s',
          '&:hover': {
            transform: {
              lg: 'rotate(-90deg)',
            },
          },
        })}
      >
        <Box
          sx={(theme) => ({
            transform: {
              xs: 'translateY(0px)',
              lg: 'translateY(0px)',
            },
            zIndex: 9999,
            borderTopLeftRadius: { xs: theme.spacing(6), lg: theme.spacing(8) },
            borderTopRightRadius: {
              xs: theme.spacing(6),
              lg: theme.spacing(8),
            },
            backgroundColor: theme.palette.primary.main,
            transition: 'all 0.2s ease-in-out 0s',
            '&:hover': {
              transform: {
                lg: 'translateY(-12px)',
              },
            },
          })}
        >
          <Button
            to={!!linkTo ? (linkTo as unknown as string) : externalUrl}
            isExternal={!!externalUrl}
            sx={{
              '&.MuiButton-root': {
                position: 'relative',
                borderBottomLeftRadius: '0px',
                borderBottomRightRadius: '0px',
                paddingTop: { xs: '16px', lg: '24px' },
                paddingBottom: { xs: '16px', lg: '24px' },
                fontSize: { xs: '18px', lg: '24px' },
                height: { xs: '56px', lg: '76px' },
                '&:hover': {
                  opacity: 1,
                  backgroundColor: '#da344e',
                },
                '&:focus': {
                  boxShadow: 'none',
                },
              },
            }}
          >
            {getText('btn.sticky.jobs')}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
