import { Components as MuiComponents, Theme } from '@mui/material'
import { ButtonOverrides } from './components/Button'
import { ChipOverrides } from './components/Chip'
import { CssBaselineOverrides } from './components/CssBaseline'
import { SvgIconOverrides } from './components/SvgIcon'
import { LinkOverrides } from './components/Link'
import { ContainerOverrides } from './components/Container'
import { MenuOverrides } from './components/Menu'
import {
  AccordionDetailsOverrides,
  AccordionOverrides,
  AccordionSummaryOverrides,
} from './components/Accordion'
import {
  MuiFormControlOverrides,
  //MuiInputBaseOverrides,
  //MuiOutlinedInputOverrides,
  //MuiFilledInputOverrides,
  MuiFormHelperTextOverrides,
  MuiInputLabelOverrides,
} from './components/Formfields'
import { CardOverrides } from './components/Card'
import { PaperOverrides } from './components/Paper'
import { TypographyOverrides } from './components/Typography'

export const ComponentOverrides = (theme: Theme): MuiComponents => ({
  MuiCssBaseline: CssBaselineOverrides(theme),
  MuiSvgIcon: SvgIconOverrides(theme),
  MuiButton: ButtonOverrides(theme),
  MuiChip: ChipOverrides(theme),
  MuiAccordion: AccordionOverrides(theme),
  MuiAccordionSummary: AccordionSummaryOverrides(theme),
  MuiAccordionDetails: AccordionDetailsOverrides(theme),
  MuiFormControl: MuiFormControlOverrides(theme),
  //MuiInputBase: MuiInputBaseOverrides(theme),
  //MuiOutlinedInput: MuiOutlinedInputOverrides(theme),
  //MuiFilledInput: MuiFilledInputOverrides(theme),
  MuiFormHelperText: MuiFormHelperTextOverrides(theme),
  MuiInputLabel: MuiInputLabelOverrides(theme),
  MuiLink: LinkOverrides(theme),
  MuiContainer: ContainerOverrides(theme),
  MuiMenu: MenuOverrides(theme),
  MuiCard: CardOverrides(theme),
  MuiPaper: PaperOverrides(theme),
  MuiTypography: TypographyOverrides(theme),
})
