import React, { ReactElement } from 'react'
import { useLocation } from '@reach/router'

import { Box, styled, Typography } from '@mui/material'

import theme from '@config/theme'
import Link from '@components/core/ui/link'
import { INavItemData } from '@components/modules/navigation/drawerNav'

export interface INavItem {
  item: INavItemData
  onClick: () => void
  onNavigate: () => void
  isOpen: boolean
}

const SubItem = styled('li')({
  padding: '16px 32px',
  '&:not(:last-of-type)': {
    boxShadow: '0px 1px 0px 0px rgba(27, 30, 32, 0.1)',
  },
})

export default function NavItem({
  item,
  onClick,
  onNavigate,
  isOpen,
}: INavItem): ReactElement {
  const location = useLocation()

  return (
    <Box
      component="li"
      role="none"
      sx={{
        '&:not(:first-of-type)': {
          marginLeft: theme.spacing(10),
        },
      }}
    >
      <Typography
        component={Link}
        variant="navigation"
        className={
          !!item.children && location.pathname.startsWith(item.url)
            ? 'is-active'
            : ''
        }
        sx={{
          position: 'relative',
          display: 'block',
          borderBottom: '2px dotted transparent',
          color: isOpen ? 'primary.main' : undefined,
          '&:after': {
            content: `''`,
            position: 'absolute',
            left: 0,
            bottom: -2,
            width: '0%',
            height: 2,
            backgroundColor: 'primary.main',
            transition: `width .4s ease-in-out`,
          },
          '&.is-active': {
            color: 'primary.main',
            '&:after': {
              width: '100%',
            },
          },
          '&:focus': {
            outline: 'none',
            width: '100%',
          },
        }}
        to={!!item.children ? undefined : item.url}
        activeClassName="is-active"
        onClick={onClick}
      >
        {item.label}
      </Typography>
      {!!item.children && (
        <Box
          component="div"
          sx={{
            position: 'absolute',
            top: '100%',
            width: 304,
            overflow: 'hidden',
            transform: 'translateX(-64px)',
            padding: 8,
            pt: 0,
            pointerEvents: 'none',
            '&:before, &:after': {
              content: '""',
              position: 'absolute',
              backgroundColor: 'transparent',
              bottom: 'auto',
              height: isOpen ? 42 : 0,
              width: 16,
              top: 0,
              borderTopRightRadius: 16,
              boxShadow: ' 0 -16px 0 0 #fff',
              left: 16,
              transition: 'all .1s ease-in-out .1s',
              zIndex: 1,
            },
            '&:after': {
              borderTopRightRadius: 0,
              borderTopLeftRadius: 16,
              left: 'auto',
              right: 16,
            },
          }}
        >
          <Box
            component="ul"
            sx={{
              p: 0,
              pb: 2,
              listStyle: 'none',
              background: '#fff',
              borderRadius: '0 0 16px 16px',
              transform: isOpen ? 'translateY(0)' : 'translateY(-120%)',
              transition: 'all .3s ease-in-out',
              boxShadow: '0 2px 4px 0 rgba(27, 30, 32, 0.1)',
              pointerEvents: 'all',
              zIndex: 0,
            }}
          >
            {item.children.map((subItem, idx) => (
              <SubItem key={idx}>
                <Typography
                  component={Link}
                  variant="navigation2"
                  to={subItem.url}
                  onClick={onNavigate}
                  activeClassName="active"
                >
                  {subItem.label}
                </Typography>
              </SubItem>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  )
}
