import React, { ReactElement } from 'react'

import {
  styled,
  Box,
  IconButton,
  Typography,
  useMediaQuery,
  Theme,
  Container,
  Grid,
} from '@mui/material'

import useGlobalText from '@components/system/hooks/useGlobalText'

import Link from '@components/core/ui/link'
import ToTopButton from './totop'
import Icon from '@components/core/ui/icon'

import LampenweltLogo from '@static/img/logo_composite_negative.svg'
import GoogleAppstoreLogo from '@static/img/google-play_badge_DE.svg'
import AppleAppstoreLogo from '@static/img/app-store_badge_EN.svg'

export type FooterProps = LAW.Props.IDefault & {
  navigationItems: LAW.Props.LinkTo[]
  navigationItemsSocial: LAW.Props.NavLink[]
}

const StyledAppStoreLink = styled('a')(() => ({
  cursor: 'pointer',
  height: '35px',
}))

export default function Footer({
  navigationItems,
  navigationItemsSocial,
}: FooterProps): ReactElement {
  const { getText } = useGlobalText()
  const isMobile = useMediaQuery((muiTheme: Theme) =>
    muiTheme.breakpoints.down('lg')
  )

  function renderNavBlock() {
    return (
      <Box
        sx={(muiTheme: Theme) => ({
          [muiTheme.breakpoints.up('lg')]: {
            display: 'flex',
          },
        })}
      >
        <Box
          sx={(muiTheme: Theme) => ({
            mt: 20,
            mb: 12,
            textAlign: 'center',
            [muiTheme.breakpoints.up('lg')]: {
              order: 2,
              my: 0,
              textAlign: 'left',
              flex: 1,
              alignSelf: 'flex-end',
            },
          })}
        >
          <Link
            iconColor="inherit"
            fontSize="small"
            onClick={() => {
              if (!window.__cmp) return

              window.__cmp('showGDPRScreen')
            }}
            sx={{
              position: 'relative',
              marginRight: { xs: 6, lg: 10 },
              verticalAlign: 'unset',
            }}
          >
            {getText('footer.cmpsettings.open')}
          </Link>
          {navigationItems?.map((item, index) => (
            <Link
              key={index}
              to={item.url}
              isExternal
              iconColor="inherit"
              fontSize="small"
              sx={{
                position: 'relative',
                '&:not(:last-of-type)': {
                  marginRight: { xs: 6, lg: 10 },
                },
              }}
            >
              {item.label}
            </Link>
          ))}
        </Box>
        <Box
          sx={(muiTheme: Theme) => ({
            textAlign: 'center',
            [muiTheme.breakpoints.up('lg')]: {
              order: 1,
              textAlign: 'left',
              alignSelf: 'flex-end',
              mr: 10,
            },
          })}
        >
          <Typography variant="footer">
            {getText('footer.copyright')}
          </Typography>
        </Box>
      </Box>
    )
  }

  function renderSocialBlock() {
    return (
      <Box
        sx={(muiTheme: Theme) => ({
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          [muiTheme.breakpoints.up('lg')]: {
            alignItems: 'flex-start',
            order: 4,
            mr: 'auto',
          },
        })}
      >
        <Typography variant="footer" fontWeight="600" sx={{ mb: 4 }}>
          {getText('footer.social')}
        </Typography>
        <Box
          sx={(muiTheme: Theme) => ({
            mb: 12,
            [muiTheme.breakpoints.up('lg')]: {
              ml: -2,
            },
          })}
        >
          {navigationItemsSocial?.map((item, index) => (
            <IconButton
              color="inherit"
              key={index}
              href={item.url}
              target="_blank"
              rel="noreferrer"
            >
              <Icon
                name={item.icon}
                color="inherit"
                fontSize="large"
                sx={(theme) => ({
                  '& path': {
                    fill: '#fff',
                  },
                  '& use': {
                    fill: '#fff',
                  },
                  [theme.breakpoints.up('lg')]: {
                    '&:hover': {
                      opacity: '60%',
                    },
                  },
                })}
              />
            </IconButton>
          ))}
        </Box>
        <Typography variant="footer" fontWeight="600" sx={{ mb: 4 }}>
          {getText('footer.app')}
        </Typography>
        <Box
          sx={{
            display: 'flex',
          }}
        >
          <StyledAppStoreLink
            target="_blank"
            href="https://apps.apple.com/de/app/lampenwelt-de/id417218315"
            sx={{
              mr: 4,
            }}
          >
            <AppleAppstoreLogo height="inherit" />
          </StyledAppStoreLink>
          <StyledAppStoreLink
            target="_blank"
            href="https://play.google.com/store/apps/details?id=com.shopgate.android.app10189"
          >
            <GoogleAppstoreLogo height="inherit" />
          </StyledAppStoreLink>
        </Box>
      </Box>
    )
  }

  return (
    <Box
      sx={{
        bgcolor: 'white',
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          height: '50px',
          marginBottom: '1em',
        }}
      >
        <ToTopButton />
      </Box>

      <Box
        component="footer"
        sx={{
          color: 'text.inverted',
          position: 'relative',
          bgcolor: 'background.primary',
          borderTopLeftRadius: { xs: '32px', md: '40px' },
          borderTopRightRadius: { xs: '32px', md: '40px' },
        }}
      >
        <Container maxWidth="xl">
          <Grid
            container
            sx={{
              pt: 20,
              pb: 20,
            }}
          >
            <Grid item xs={12} lg={9}>
              <Box
                sx={(muiTheme: Theme) => ({
                  textAlign: 'center',
                  mb: 20,
                  [muiTheme.breakpoints.up('lg')]: {
                    textAlign: 'left',
                    mb: 33,
                  },
                })}
              >
                <LampenweltLogo
                  width={isMobile ? '160px' : '200px'}
                  height={isMobile ? '32px' : '40px'}
                  alt=""
                />
              </Box>
              {!isMobile ? renderNavBlock() : ''}
            </Grid>

            <Grid item xs={12} lg={3}>
              {renderSocialBlock()}
              {isMobile ? renderNavBlock() : ''}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  )
}
