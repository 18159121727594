import { useContext } from 'react'

import {
  LangSwitchContext,
  ILangSwitchContext,
} from '../providers/LangSwitchProvider'

export default function useLangSwitch(): ILangSwitchContext {
  const values = useContext(LangSwitchContext)

  return values
}
