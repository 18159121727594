import React, { ReactElement } from 'react'
import {
  Link as MuiLink,
  Box,
  styled,
  Theme,
  SxProps,
  useTheme,
  useMediaQuery,
} from '@mui/material'
import { Link as GatsbyLink } from 'gatsby'
import clsx from 'clsx'
import { IconProps } from '../icon'
import Arrow from './arrow'

export type LinkProps = LAW.Props.IDefault & {
  id?: string
  to?: string
  withIcon?: boolean
  isExternal?: boolean
  icon?: IconProps
  iconColor?: string
  fontSize?: 'small' | 'medium' | 'large' | 'inherit'
  fontWeight?: 'normal' | 'bold'
  onClick?: (event: React.SyntheticEvent) => void
  activeClassName?: string
}

export default function Link({
  id,
  children,
  className,
  to,
  activeClassName,
  iconColor,
  withIcon = false,
  isExternal = false,
  fontSize,
  fontWeight,
  onClick,
  sx = {},
}: LinkProps): ReactElement {
  const theme = useTheme()
  const isLarge = useMediaQuery(theme.breakpoints.up('md'))

  const classes = clsx(className, {
    ['MuiLink-root']: true,
    ['MuiLink-fontSizeSmall']: fontSize === 'small',
    ['MuiLink-fontSizeMedium']: fontSize === 'medium',
    ['MuiLink-fontSizeLarge']: fontSize === 'large',
    ['MuiLink-fontWeightNormal']: fontWeight === 'normal',
    ['MuiLink-fontWeightBold']: fontWeight === 'bold',
  })

  const LinkComponent =
    !to && onClick ? 'button' : !isExternal ? GatsbyLink : 'a'

  const additionalProps =
    !to && onClick
      ? {}
      : isExternal
      ? { href: to, target: '_blank', rel: 'noreferrer' }
      : { to, activeClassName }

  const SxSpan = styled('span')({})

  const iconSX: SxProps = {
    display: 'flex',
  }

  const iconHoverSX: SxProps = {
    transition: '0.2s',
    '&:hover': {
      mr: '-16px',
      transition: '0.2s',
      '& .MuiBox-root': {
        '& #arrow': {
          width: '54px',
        },
      },
    },
  }

  const noIconSX: SxProps = {
    '&:hover': {
      color: 'primary.main',
    },
  }

  return (
    <MuiLink
      id={id}
      className={classes}
      component={LinkComponent}
      underline="none"
      onClick={onClick}
      {...additionalProps}
      sx={() => ({
        ...sx,
        ...(withIcon ? iconSX : noIconSX),
        ...(withIcon && isLarge && iconHoverSX),
      })}
    >
      {withIcon === true ? (
        <>
          <SxSpan
            sx={(muiTheme: Theme) => ({
              color: !!iconColor ? iconColor : muiTheme.palette.text.primary,
              '&:hover': {
                color:
                  withIcon === true
                    ? !!iconColor
                      ? iconColor
                      : muiTheme.palette.text.primary
                    : muiTheme.palette.primary.main,
              },
            })}
          >
            {children}
          </SxSpan>
          <Box
            component="span"
            sx={{
              display: 'flex',
              alignItems: 'center',
              ml: 4,
            }}
          >
            <Arrow iconColor={iconColor} />
          </Box>
        </>
      ) : (
        children
      )}
    </MuiLink>
  )
}
