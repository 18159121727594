import React, { useEffect, useState } from 'react'

export interface IAnimationContext {
  isDownScroll: boolean
}

export const AnimationContext = React.createContext<IAnimationContext>({
  isDownScroll: false,
})

export type AnimationProviderProps = {
  children: React.ReactNode
}

export default function AnimationProvider({
  children,
}: AnimationProviderProps): React.ReactElement {
  const [isDownScroll, setIsDownScroll] = useState(true)
  // START - scroll direction detection
  useEffect(() => {
    const threshold = 0
    let lastScrollY = window.pageYOffset
    let ticking = false

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false
        return
      }
      setIsDownScroll(scrollY > lastScrollY ? true : false)
      lastScrollY = scrollY > 0 ? scrollY : 0
      ticking = false
    }

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir)
        ticking = true
      }
    }

    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [isDownScroll])
  // END - Scroll direction detection

  return (
    <AnimationContext.Provider value={{ isDownScroll }}>
      {children}
    </AnimationContext.Provider>
  )
}
