import React from 'react'
import { Box } from '@mui/material'
import theme from '@config/theme'

export type ArrowProps = LAW.Props.IDefault & {
  iconColor?: string
}

function Arrow({ iconColor }: ArrowProps) {
  return (
    <Box
      data-testid="arrow"
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Box
        id="arrow"
        className="arrow"
        sx={{
          backgroundColor: !!iconColor ? iconColor : theme.palette.text.primary,
          height: '1px',
          width: '38px',
          transition: '0.2s',
          //transformOrigin: 'left',
        }}
      ></Box>
      <Box
        sx={{
          marginLeft: '-10px',
          border: 'solid',
          borderColor: !!iconColor ? iconColor : 'black',
          borderWidth: '0 1px 1px 0',
          display: 'inline-block',
          padding: '4px',
          height: '1px',
          width: '1px',
          transition: '0.2s',
          transform: 'rotate(-45deg)',
        }}
      ></Box>
    </Box>
  )
}

export default Arrow
