import { TypographyOptions } from '@mui/material/styles/createTypography'
import { Theme } from '@mui/material'
import { fontFamily } from '../definitions/fontFamily'

declare module '@mui/material/styles' {
  interface TypographyVariants {
    navigation: React.CSSProperties
    navigation2: React.CSSProperties
    footer: React.CSSProperties
    footerTitle: React.CSSProperties
    chip: React.CSSProperties
    chip2: React.CSSProperties
    quote: React.CSSProperties
    teaser: React.CSSProperties
    caption2: React.CSSProperties
    fact: React.CSSProperties
    benefit: React.CSSProperties
    body3: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    navigation?: React.CSSProperties
    navigation2?: React.CSSProperties
    footer?: React.CSSProperties
    footerTitle?: React.CSSProperties
    chip?: React.CSSProperties
    chip2?: React.CSSProperties
    quote?: React.CSSProperties
    teaser?: React.CSSProperties
    caption2?: React.CSSProperties
    fact?: React.CSSProperties
    benefit?: React.CSSProperties
    body3?: React.CSSProperties
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  export interface TypographyPropsVariantOverrides {
    navigation: true
    navigation2: true
    footer: true
    footerTitle: true
    chip: true
    chip2: true
    quote: true
    teaser: true
    subline: true
    caption2: true
    fact: true
    benefit: true
  }
}

export const TypographyOverrides = (theme: Theme): TypographyOptions => {
  return {
    ...theme.typography,
    fontFamily: fontFamily,
    body1: {
      fontFamily: fontFamily,
      fontSize: 18,
      lineHeight: 1.67,
      [theme.breakpoints.up('lg')]: {
        fontSize: 24,
        lineHeight: 1.5,
      },
    },
    body2: {
      fontFamily: fontFamily,
      fontSize: 16,
      lineHeight: 1.75,
      [theme.breakpoints.up('lg')]: {
        fontSize: 18,
        lineHeight: 1.67,
      },
    },
    body3: {
      fontFamily: fontFamily,
      fontSize: 14,
      lineHeight: 1.71,
    },
    h1: {
      fontSize: 40,
      lineHeight: 1.25,
      fontWeight: 600,
      letterSpacing: '-0.2px',
      [theme.breakpoints.up('lg')]: {
        fontSize: 72,
        letterSpacing: '-1px',
        lineHeight: 1.22,
      },
    },
    h2: {
      fontSize: 32,
      lineHeight: 1.25,
      fontWeight: 600,
      [theme.breakpoints.up('lg')]: {
        fontSize: 52,
        letterSpacing: '-0.5px',
        lineHeight: 1.23,
      },
    },
    h3: {
      fontSize: 24,
      lineHeight: 1.33,
      fontWeight: 600,
      [theme.breakpoints.up('lg')]: {
        fontSize: 32,
        lineHeight: 1.25,
      },
    },
    h4: {
      fontSize: 18,
      lineHeight: 1.33,
      fontWeight: 600,
      [theme.breakpoints.up('lg')]: {
        fontSize: 24,
        lineHeight: 1.33,
      },
    },
    quote: {
      fontSize: 24,
      lineHeight: 1.33,
      fontWeight: 600,
      color: theme.palette.primary.main,
      [theme.breakpoints.up('lg')]: {
        fontSize: 32,
        lineHeight: 1.25,
      },
    },
    fact: {
      fontSize: 28,
      lineHeight: 1.286,
      fontWeight: 600,
      color: theme.palette.primary.main,
      [theme.breakpoints.up('lg')]: {
        fontSize: 42,
        lineHeight: 1.238,
      },
    },
    benefit: {
      fontSize: 16,
      lineHeight: 1.75,
      fontWeight: 600,
      color: theme.palette.text.inverted,
      [theme.breakpoints.up('lg')]: {
        fontSize: 24,
        lineHeight: 1.5,
      },
    },
    caption: {
      fontSize: 16,
      lineHeight: 1.5,
      fontWeight: 600,
      color: theme.palette.primary.main,
      [theme.breakpoints.up('lg')]: {
        fontSize: 18,
        lineHeight: 1.33,
      },
    },
    caption2: {
      fontSize: 14,
      lineHeight: 1.43,
      fontWeight: 600,
      color: theme.palette.primary.main,
      [theme.breakpoints.up('lg')]: {
        fontSize: 16,
        lineHeight: 1.5,
      },
    },
    navigation: {
      fontSize: 18,
      lineHeight: 1.33,
      fontWeight: 500,
    },
    navigation2: {
      fontSize: 16,
      lineHeight: 1.5,
      fontWeight: 500,
    },
    footer: {
      fontSize: 14,
      lineHeight: 1.7,
      textDecoration: 'none',
    },
    footerTitle: {
      fontSize: 14,
      lineHeight: 1.7,
      fontWeight: 600,
    },
    chip: {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: 1.5,
      [theme.breakpoints.up('lg')]: {
        fontSize: 16,
      },
    },
    chip2: {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: 1.75,
      [theme.breakpoints.up('lg')]: {
        fontSize: 18,
        lineHeight: 1.6667,
      },
    },
  }
}
