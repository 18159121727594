import '@formatjs/intl-getcanonicallocales/polyfill'
import '@formatjs/intl-pluralrules/polyfill'
import '@formatjs/intl-pluralrules/locale-data/en'
import '@formatjs/intl-pluralrules/locale-data/de'

import React, { ReactElement } from 'react'
import * as ReactIntl from 'react-intl'

import { WrapPageElementNodeArgs } from 'gatsby'

import Layout from '@components/core/container/layout'

import { messages } from '../../translations'
import { defaultLang } from '../../translations'

import { ThemeProvider as MuiThemeProvider } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'

import { SnackbarProvider } from '@components/system/providers/SnackbarProvider'
import AnimationProvider from '@components/system/providers/AnimationProvider'
import GlobalTextProvider from '@components/system/providers/GlobalTextProvider'
import LangSwitchProvider from './providers/LangSwitchProvider'
import { ThemeProvider } from './providers/themeProvider'

import theme from '@config/theme'

type PageWrapperProps = WrapPageElementNodeArgs & {
  props: {
    pageContext: LAW.Helpers.IPageContext
    location: Record<string, boolean>
  }
}

export function PageWrapper({
  element,
  props,
}: PageWrapperProps): ReactElement {
  return (
    <ReactIntl.IntlProvider
      locale={props.pageContext.locale || defaultLang}
      defaultLocale={defaultLang}
      messages={messages[props.pageContext.locale] || messages[defaultLang]}
    >
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <SnackbarProvider>
          <ThemeProvider name={props.pageContext.theme}>
            <AnimationProvider>
              <GlobalTextProvider pageContext={props.pageContext}>
                <LangSwitchProvider>
                  <Layout pageContext={props.pageContext}>{element}</Layout>
                </LangSwitchProvider>
              </GlobalTextProvider>
            </AnimationProvider>
          </ThemeProvider>
        </SnackbarProvider>
      </MuiThemeProvider>
    </ReactIntl.IntlProvider>
  )
}
