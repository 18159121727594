import poppinsRegularWoff from '../../../static/font/Poppins-Regular.woff'
import poppinsMediumWoff from '../../../static/font/Poppins-Medium.woff'
import poppinsSemiboldWoff from '../../../static/font/Poppins-SemiBold.woff'

export const poppinsRegular = {
  fontFamily: 'Poppins',
  fontDisplay: 'swap',
  fontStyle: 'normal',
  fontWeight: 400,
  src: `url(${poppinsRegularWoff}) format('woff')`,
}

export const poppinsMedium = {
  fontFamily: 'Poppins',
  fontDisplay: 'swap',
  fontStyle: 'normal',
  fontWeight: 500,
  src: `url(${poppinsMediumWoff}) format('woff')`,
}

export const poppinsSemibold = {
  fontFamily: 'Poppins',
  fontDisplay: 'swap',
  fontStyle: 'normal',
  fontWeight: 600,
  src: `url(${poppinsSemiboldWoff}) format('woff')`,
}

export const fontFamily = 'Poppins, Helvetica, Arial, sans-serif'
