import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

/**
 * @description Possible theme fonts: ptsans, default
 */
const ThemeContext = React.createContext({
  name: 'default',
})

export function ThemeProvider({ children, name }) {
  const [theme, setTheme] = useState(name)

  useEffect(() => {
    setTheme(() => {
      return name
    })
  }, [name])

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  )
}

ThemeProvider.propTypes = {
  children: PropTypes.any,
  name: PropTypes.string,
}

ThemeProvider.defaultProps = {
  name: 'default',
}

export default ThemeContext
