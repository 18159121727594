import React, { ReactElement } from 'react'
import { Button as MuiButton, SxProps } from '@mui/material'
import { styled } from '@mui/system'
import { Link as GatsbyLink } from 'gatsby'

import Icon, { IconNamesType } from '../icon'

import clsx from 'clsx'

export type ButtonProps = LAW.Props.IDefault & {
  id?: string
  to?: string
  onClick?: (event: React.SyntheticEvent) => void
  variant?: 'text' | 'primary' | 'outlined' | 'round' | 'totop'
  size?: 'small' | 'medium' | 'large'
  icon?: IconNamesType
  isExternal?: boolean
  isActive?: boolean
  disabled?: boolean
  sx?: SxProps
}

const variantProps: { [key: string]: unknown } = {
  primary: { variant: 'contained', color: 'primary' },
  text: { variant: 'text' },
  outlined: { variant: 'outlined' },
  round: { variant: 'round' },
}

const SliderButton = styled('button')(({ theme }) => ({
  border: 'none',
  outline: 'none',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  width: '68px',
  height: '68px',
  padding: 0,
  color: '#ffffff',
  backgroundColor: theme.palette.grey[900],
  [theme.breakpoints.up('lg')]: {
    '&:hover': {
      transition: 'all .2s ease-in-out 0s',
      opacity: 0.6,
    },
  },
  ':disabled': {
    color: theme.palette.grey[300],
    backgroundColor: theme.palette.grey[100],
  },
  '&:not(:disabled)': {
    cursor: 'pointer',
  },
}))

const ToTopButton = styled('button')(({ theme }) => ({
  border: 'none',
  outline: 'none',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  width: '68px',
  height: '68px',
  padding: 0,
  color: '#ffffff',
  backgroundColor: theme.palette.grey[200],
  ':disabled': {
    color: theme.palette.grey[300],
    backgroundColor: theme.palette.grey[100],
  },
  '&:not(:disabled)': {
    cursor: 'pointer',
  },
  [theme.breakpoints.up('lg')]: {
    '&:hover': {
      transition: 'all .2s ease-in-out 0s',
      opacity: 1,
    },
  },
}))

/**
 * MUI Button Wrapper
 */
export default function Button({
  id,
  to,
  onClick,
  variant = 'primary',
  icon,
  size,
  children,
  isExternal,
  isActive,
  disabled,
  sx = {},
}: ButtonProps): ReactElement {
  const className = clsx({
    ['Mui-active']: isActive,
  })

  const LinkComponent = !isExternal
    ? GatsbyLink
    : !to && onClick
    ? 'button'
    : 'a'
  const additionalProps = isExternal
    ? { href: to, target: '_blank', rel: 'noreferrer' }
    : { to }

  if (variant === 'round' && icon) {
    return (
      <SliderButton
        id={id}
        className={className}
        onClick={onClick}
        disabled={disabled}
        sx={sx}
      >
        <Icon
          fontSize="small"
          name={icon}
          sx={{
            width: '1.25em',
            height: '1.25em',
          }}
        />
      </SliderButton>
    )
  } else if (variant === 'totop') {
    return (
      <ToTopButton
        disabled={disabled}
        id={id}
        className={className}
        onClick={onClick}
      >
        <Icon
          fontSize="small"
          name="ArrowUp"
          sx={{
            width: '1.25em',
            height: '1.25em',
          }}
        />
      </ToTopButton>
    )
  } else {
    return (
      <MuiButton
        disabled={disabled}
        id={id}
        className={className}
        component={LinkComponent}
        endIcon={children && icon ? <Icon name={icon} /> : undefined}
        underline="none"
        size={size}
        onClick={onClick}
        {...variantProps[variant]}
        {...additionalProps}
        sx={sx}
      >
        {children && children}
      </MuiButton>
    )
  }
}
