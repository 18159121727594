import Spacing from '@config/theme/definitions/spacing'
import { Components, Theme } from '@mui/material'

export const ContainerOverrides = (
  theme: Theme
): Components['MuiContainer'] => ({
  variants: [
    {
      props: { maxWidth: 'md' },
      style: {
        paddingLeft: theme.spacing(6),
        paddingRight: theme.spacing(6),
        [theme.breakpoints.up('sm')]: {
          paddingLeft: theme.spacing(6),
          paddingRight: theme.spacing(6),
        },
        [theme.breakpoints.up('md')]: {
          maxWidth: `${Spacing.maxWidth.md}px !important`,
          boxSizing: 'content-box',
          paddingLeft: theme.spacing(8),
          paddingRight: theme.spacing(8),
        },
      },
    },
    {
      props: { maxWidth: 'lg' },
      style: {
        maxWidth: `${Spacing.maxWidth.lg}px !important`,
        paddingLeft: theme.spacing(6),
        paddingRight: theme.spacing(6),
        [theme.breakpoints.up('sm')]: {
          paddingLeft: theme.spacing(6),
          paddingRight: theme.spacing(6),
        },
        [theme.breakpoints.up('md')]: {
          paddingLeft: theme.spacing(8),
          paddingRight: theme.spacing(8),
        },
        [theme.breakpoints.up('xl')]: {
          paddingLeft: theme.spacing(34),
          paddingRight: theme.spacing(34),
        },
      },
    },
    {
      props: { maxWidth: 'xl' },
      style: {
        maxWidth: `${Spacing.maxWidth.xl}px !important`,
        paddingLeft: theme.spacing(6),
        paddingRight: theme.spacing(6),
        [theme.breakpoints.up('sm')]: {
          paddingLeft: theme.spacing(6),
          paddingRight: theme.spacing(6),
        },
        [theme.breakpoints.up('md')]: {
          paddingLeft: theme.spacing(8),
          paddingRight: theme.spacing(8),
        },
        [theme.breakpoints.up('xl')]: {
          paddingLeft: theme.spacing(34),
          paddingRight: theme.spacing(34),
        },
      },
    },
  ],
})
