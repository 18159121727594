import { FontSizeType } from '@mui/material/styles/createTheme'

const FontSize: FontSizeType = new Map([
  ['xs', '13px'], //
  ['sm', '14px'], // caption mobile small
  ['base', '16px'], // caption desk small, body copy mobile small, caption mobile
  ['baseXS', '16px'],
  ['lg', '18px'], // body copy desk small, caption desk, h4 mobile, body copy mobile
  ['2lg', '20px'], //
  ['3lg', '22px'], //
  ['xl', '24px'], // h4 desk, quote mobile, body copy desk, h3 mobile
  ['2xl', '32px'], // h3 desk, quote desk, h2 mobile
  ['3xl', '40px'], // h1 mobile
  ['4xl', '42px'],
  ['5xl', '52px'], // h2 desk
  ['6xl', '72px'], // h1 desk
  ['iconSmall', '16px'], // icon small <--vorläufig
  ['iconDefault', '24px'], // icon default <--vorläufig
  ['iconLarge', '32px'], // icon large <--vorläufig
])

export default FontSize
