import { Theme } from '@mui/material/'
import {
  ColorPartial,
  PaletteOptions,
} from '@mui/material/styles/createPalette'

/**
 * Used https://material.io/design/color/the-color-system.html#tools-for-picking-colors
 * for [Shade] = hex based on styleguide colors.
 */
const Colors: Record<string, ColorPartial> = {
  red: {
    50: '#ffeaea',
    100: '#ffcad3',
    200: '#ef949b',
    // style guide color
    300: '#E92444',
    400: '#f04153',
    500: '#f62339',
    600: '#e71538',
    // style guide color
    700: '#DA344E',
    // style guide color
    800: '#C02D45',
    900: '#ba001e',
  },
  green: {
    100: '#00754B',
  },
  blue: {
    50: '#cceaff',
    100: '#99d5ff',
    200: '#66bfff',
    // style guide color
    300: '#5375AB',
    // style guide color
    400: '#1F839F',
    500: '#0077cc',
    // style guide color
    600: '#0064AA',
    700: '#004a80',
    800: '#002d4d',
    900: '#000f1a',
  },
  grey: {
    // style guide color
    100: '#F4F4F5',
    200: '#ededed',
    // style guide color
    300: '#B9BEC2',
    // style guide color
    400: '#AAAAAA',
    // style guide color
    500: '#737B80',
    600: '#5d5d5d',
    // style guide color
    700: '#434B4F',
    // style guide color
    800: '#242F32',
    // style guide color
    900: '#1B1E20',
  },
}
export const PaletteOverrides = (theme: Theme): PaletteOptions => ({
  ...theme.palette,
  mode: 'light',
  primary: {
    main: Colors.red[700] || '',
    dark: Colors.red[800],
    ...Colors.red,
  },
  secondary: {
    main: Colors.grey[800],
    ...Colors.grey,
  },
  tertiary: {
    main: Colors.blue[400],
    ...Colors.blue,
  },
  sustainability: {
    main: Colors.green[100],
  },
  background: {
    ...theme.palette.background,
    main: theme.palette.common.white,
    primary: Colors.grey[900],
    secondary: Colors.grey[300],
    grey: Colors.grey,
    light: Colors.grey[100],
    red: Colors.red[700],
    dark: Colors.grey[900],
    green: Colors.green[100],
  },
  text: {
    ...theme.palette.text,
    main: Colors.grey[900],
    primary: Colors.grey[900],
    secondary: Colors.grey[300],
    inverted: theme.palette.common.white,
  },
  grey: Colors.grey,
})
