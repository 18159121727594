import React, { ReactElement, useState } from 'react'

import useGlobalText from '@components/system/hooks/useGlobalText'
import { SwipeableDrawer, Typography } from '@mui/material'
import ListItem from '@mui/material/ListItem'
import theme from '@config/theme'
import Link from '@components/core/ui/link'
import Icon from '@components/core/ui/icon'

export interface IDrawerNavItem extends LAW.Helpers.IReactDefaultProps {
  to?: string
  label: string
  onNavigate: () => void
  navHeight: number
}

export default function DrawerNavItem({
  to,
  label,
  children,
  onNavigate,
  navHeight,
}: IDrawerNavItem): ReactElement {
  const [open, setOpen] = useState(false)
  const { getText } = useGlobalText()

  return (
    <>
      <ListItem
        button
        sx={{
          padding: 0,
          boxShadow: `0px 1px 2px 0px ${theme.palette.grey[200]}`,
        }}
      >
        <Typography
          component={Link}
          variant="navigation"
          to={!!children ? undefined : to}
          onClick={() => {
            if (children) {
              setOpen(true)
            } else {
              onNavigate()
            }
          }}
          sx={{
            flex: 1,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            px: 6,
            py: 5,
          }}
        >
          {label}
          {!!children && (
            <Icon
              name="ChevronRight"
              sx={{
                fontSize: 'xs',
                color: theme.palette.grey[300],
              }}
            />
          )}
        </Typography>
      </ListItem>
      {children && (
        <SwipeableDrawer
          anchor={'right'}
          open={open}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          hideBackdrop
          variant="persistent"
          PaperProps={{
            sx: {
              pt: 20,
              pb: '1px',
              boxShadow: 'none',
              border: 0,
              width: '100%',
              height: 'auto',
              minHeight: navHeight,
            },
          }}
        >
          <Typography
            component="div"
            variant="navigation"
            sx={{
              display: 'flex',
              alignItems: 'center',
              pl: 4,
              pr: 6,
              py: 5,
              boxShadow: `0px 1px 2px 0px ${theme.palette.grey[200]}`,
              color: 'primary.main',
            }}
            onClick={() => setOpen(false)}
          >
            <Icon name="ChevronLeft" sx={{ mr: 2 }} />
            {getText('navigation.back')}
          </Typography>
          {children}
        </SwipeableDrawer>
      )}
    </>
  )
}
