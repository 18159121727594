import React, { ReactElement, useState, useEffect } from 'react'
import { Link as GatsbyLink } from 'gatsby'

import {
  AppBar,
  Toolbar,
  Box,
  IconButton,
  Container,
  useMediaQuery,
  Theme,
} from '@mui/material'

import theme from '@config/theme'

import HorizontalNav from '@components/modules/navigation/horizontalNav'
import DrawerNav from '@components/modules/navigation/drawerNav'
import { INavItemData } from '@components/modules/navigation/drawerNav'
import Burger from '@components/core/ui/burger'
import Button from '@components/core/ui/button'

import useGlobalText from '@components/system/hooks/useGlobalText'

import IconLogo from '@static/img/logos/logo_composite.svg'
import useLangSwitch from '@components/system/hooks/useLangSwitch'

export type HeaderProps = {
  mainnav: Array<INavItemData>
}

export default function Header({ mainnav }: HeaderProps): ReactElement {
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [scrollDir, setScrollDir] = useState('down')
  const { getText } = useGlobalText()
  const { langSwitchLink } = useLangSwitch()

  const isMobile = useMediaQuery((muiTheme: Theme) =>
    muiTheme.breakpoints.down('lg')
  )

  useEffect(() => {
    const threshold = 10
    let lastScrollY = window.pageYOffset
    let ticking = false

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset

      if (Math.abs(scrollY - lastScrollY) < threshold && scrollY > 0) {
        ticking = false
        return
      }
      setScrollDir(scrollY > lastScrollY || scrollY === 0 ? 'down' : 'up')
      lastScrollY = scrollY > 0 ? scrollY : 0
      ticking = false
    }

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir)
        ticking = true
      }
    }

    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [scrollDir])

  return (
    <React.Fragment>
      <AppBar
        elevation={0}
        position={scrollDir === 'up' ? 'sticky' : 'relative'}
        color="inherit"
        sx={{
          zIndex: theme.zIndex.modal,
          boxShadow:
            scrollDir === 'up' && !drawerOpen
              ? '0 2px 4px 0 rgba(27, 30, 32, 0.1)'
              : 'none',
          '@keyframes slideIn': {
            from: {
              transform: 'translateY(-100px)',
            },
            to: {
              transform: 'translateY(0)',
            },
          },
          animation: scrollDir === 'up' ? 'slideIn 0.5s linear' : 'none',
        }}
      >
        <Container maxWidth="xl">
          <Toolbar
            sx={{
              py: { xs: 4, lg: 6 },
            }}
            disableGutters
          >
            <Box
              component={GatsbyLink}
              to="/"
              onClick={() => setDrawerOpen(false)}
              sx={{
                display: 'flex',
                flexGrow: 1,
                '& > svg': { height: { xs: 32, md: 40 }, width: 'auto' },
              }}
            >
              <IconLogo />
            </Box>

            <Box
              sx={{
                flexGrow: 1,
                justifyContent: 'flex-end',
                alignItems: 'center',
                display: 'flex',
              }}
            >
              {isMobile ? (
                <>
                  <IconButton
                    size="small"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={() => setDrawerOpen(!drawerOpen)}
                    color="inherit"
                    sx={{
                      mr: -1,
                    }}
                  >
                    <Burger active={drawerOpen} />
                  </IconButton>

                  <DrawerNav
                    items={mainnav}
                    openState={drawerOpen}
                    toggleDrawer={setDrawerOpen}
                  >
                    <Button variant="outlined" to={langSwitchLink || '/'}>
                      {getText('navigation.languageswitch')}
                    </Button>
                  </DrawerNav>
                </>
              ) : (
                <>
                  <HorizontalNav items={mainnav} />
                  <Button
                    variant="outlined"
                    to={langSwitchLink || '/'}
                    sx={{
                      ml: 16,
                    }}
                  >
                    {getText('navigation.languageswitch')}
                  </Button>
                </>
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </React.Fragment>
  )
}
