import React, { ReactElement, useState } from 'react'

import { Box, SwipeableDrawer } from '@mui/material'
import List from '@mui/material/List'

import theme from '@config/theme'
import NavItem from './drawerNavItem'

export interface INavItemData {
  label: string
  url: string
  icon?: string
  children?: Array<INavItemData>
}

export interface IDrawerNav extends LAW.Helpers.IReactDefaultProps {
  items: INavItemData[]
  openState: boolean
  toggleDrawer: (open: boolean) => void
}

export default function DrawerNav({
  children,
  items,
  openState,
  toggleDrawer,
}: IDrawerNav): ReactElement {
  const [navCount, setNavCount] = useState<number>(items.length)

  function renderNavList(items: INavItemData[], level: number) {
    if (level > 0 && items.length >= navCount) {
      setNavCount(items.length + 1)
    }

    return (
      <>
        <List
          component="div"
          disablePadding
          sx={{
            ...(level === 0 && {
              lineHeight: 1,
            }),
            ...(level === 1 && {
              flex: 1,
            }),
            ...(level === 2 && {
              flex: 1,
            }),
          }}
        >
          {items.map((item) => {
            return (
              <NavItem
                key={item.url}
                to={item.url}
                label={item.label}
                onNavigate={() => toggleDrawer(false)}
                navHeight={80 + 64 * navCount}
              >
                {item.children && renderNavList(item.children, level + 1)}
              </NavItem>
            )
          })}
        </List>
      </>
    )
  }

  return (
    <SwipeableDrawer
      data-testid="drawer"
      anchor={'top'}
      open={openState}
      onClose={() => toggleDrawer(false)}
      onOpen={() => toggleDrawer(true)}
      PaperProps={{
        sx: {
          borderBottomLeftRadius: theme.spacing(8),
          borderBottomRightRadius: theme.spacing(8),
          pt: 20,
          minHeight: 176 + 64 * navCount,
        },
      }}
    >
      {renderNavList(items, 0)}
      <Box
        sx={{
          margin: theme.spacing('auto', 6, 12, 6),
        }}
      >
        {children}
      </Box>
    </SwipeableDrawer>
  )
}
