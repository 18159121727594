import React, { useRef, ReactElement, useState, useEffect } from 'react'

import { Box } from '@mui/material'

import { INavItemData } from '@components/modules/navigation/drawerNav'
import NavItem from './horizontalNavItem'

interface IHorizontalNav {
  items: INavItemData[]
}

export default function HorizontalNav({ items }: IHorizontalNav): ReactElement {
  const CompRef = useRef<HTMLElement>(null)
  const [openIndex, setOpenIndex] = useState(-1)

  useEffect(() => {
    document.addEventListener('scroll', handleScroll)
    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleScroll = () => {
    if (window.pageYOffset >= 120) {
      setOpenIndex(-1)
    }
  }

  return (
    <Box component="nav" ref={CompRef}>
      <Box
        component="ul"
        role="menubar"
        aria-label="Hauptnavigation"
        sx={{
          listStyle: 'none',
          margin: 0,
          padding: 0,
          display: 'flex',
        }}
      >
        {items?.map((item, idx) => {
          return (
            <NavItem
              key={idx}
              item={item}
              onClick={() => {
                if (openIndex === idx || !item.children) {
                  setOpenIndex(-1)
                } else {
                  setOpenIndex(idx)
                }
              }}
              onNavigate={() => {
                setOpenIndex(-1)
              }}
              isOpen={openIndex === idx}
            />
          )
        })}
      </Box>
    </Box>
  )
}
