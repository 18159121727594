import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

import Check from '../../../../../static/img/icons/check.svg'
import Close from '../../../../../static/img/icons/close.svg'
import Plus from '../../../../../static/img/icons/plus.svg'
import Minus from '../../../../../static/img/icons/minus.svg'

import ChevronRight from '../../../../../static/img/icons/chevron/right.svg'
import ChevronLeft from '../../../../../static/img/icons/chevron/left.svg'

import ArrowUp from '../../../../../static/img/icons/arrow/up.svg'
import ArrowLeft from '../../../../../static/img/icons/arrow/left.svg'
import ArrowRight from '../../../../../static/img/icons/arrow/right.svg'

import Instagram from '../../../../../static/img/icons/socials/instagram.inline.svg'
import LinkedIn from '../../../../../static/img/icons/socials/linkedin.inline.svg'
import Facebook from '../../../../../static/img/icons/socials/facebook.inline.svg'
import Youtube from '../../../../../static/img/icons/socials/youtube.inline.svg'
import Pinterest from '../../../../../static/img/icons/socials/pinterest.inline.svg'

import ScrollIndicator from '../../../../../static/img/svgIcons/scroll_indicator.inline.svg'
import PlayButton from '../../../../../static/img/svgIcons/play_button.inline.svg'

export type IconNamesType =
  | 'Check'
  | 'Close'
  | 'Minus'
  | 'Plus'
  | 'ArrowUp'
  | 'ArrowLeft'
  | 'ArrowRight'
  | 'ChevronRight'
  | 'ChevronLeft'
  | 'Instagram'
  | 'LinkedIn'
  | 'Facebook'
  | 'Youtube'
  | 'Pinterest'
  | 'ScrollIndicator'
  | 'PlayButton'

export type IconProps = LAW.Props.IDefault & {
  name: IconNamesType
  className?: string
  fontSize?: 'inherit' | 'small' | 'large' | 'medium'
  color?: 'inherit' | 'primary' | 'secondary' | 'action' | 'disabled' | 'error'
  viewBox?: string
}

const icons: { [key: string]: any } = {
  Check,
  Close,
  Minus,
  Plus,
  ArrowUp,
  ArrowLeft,
  ArrowRight,
  ChevronRight,
  ChevronLeft,
  Instagram,
  LinkedIn,
  Facebook,
  Youtube,
  Pinterest,
  ScrollIndicator,
  PlayButton,
}

export default function Icon({
  name,
  fontSize,
  color,
  viewBox,
  sx,
}: IconProps) {
  const Svg = icons[name]

  if (!Svg) return null

  return (
    <SvgIcon
      sx={sx}
      fontSize={fontSize}
      color={color}
      component={Svg}
      viewBox={
        name === 'ScrollIndicator'
          ? '0 0 68 82'
          : name === 'PlayButton'
          ? '0 0 68 68'
          : viewBox
      }
    />
  )
}
