import React, { ReactElement } from 'react'

import { Box } from '@mui/material'

import Button from '@components/core/ui/button'

export default function ToTopButton(): ReactElement {
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
  return (
    <Box
      sx={{
        position: 'relative',
        paddingTop: '1em',
        paddingBottom: '3em',
        zIndex: 10,
      }}
    >
      <Box
        sx={(theme) => ({
          top: '-5px',
          width: '68px',
          height: '68px',
          backgroundColor: 'grey.200',
          borderRadius: '50%',
          boxShadow: '0px 0px 0px 16px #FFFFFF',
          position: 'relative',
          transition: 'top ease 0.5s',
          '&::before': {
            bgcolor: 'background.primary',
            content: '""',
            display: 'block',
            position: 'absolute',
            top: '55px',
            zIndex: 5,
            width: '55px',
            height: '18px',
            borderTopRightRadius: '25px',
            border: 'inherit',
            right: '74px',
            transform: 'skew(40deg)',
            boxShadow: '15px -3px 0px 0px #FFFFFF',
            transition: 'all ease 0.5s',
          },
          '&::after': {
            bgcolor: 'background.primary',
            content: '""',
            display: 'block',
            position: 'absolute',
            top: '55px',
            zIndex: 5,
            width: '50px',
            height: '18px',
            borderTopLeftRadius: '25px',
            border: 'inherit',
            left: '74px',
            transform: 'skew(-40deg)',
            boxShadow: '-15px -3px 0px 0px #FFFFFF',
            transition: 'all ease 0.5s',
          },
          [theme.breakpoints.up('lg')]: {
            '&:hover': {
              top: '0px',
              boxShadow: '0px 0px 0px 16px #FFFFFF',
              '&::after': {
                top: '50px',
                left: '78px',
              },
              '&::before': {
                top: '50px',
                right: '78px',
              },
            },
          },
        })}
      >
        <Button variant="totop" to="#" onClick={scrollTop}></Button>
      </Box>
    </Box>
  )
}
