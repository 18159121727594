import React, { ReactElement } from 'react'
import { Helmet } from 'react-helmet'

export type MetatagsProps = {
  meta: {
    metaTitle?: string
    metaDescription?: string
    metaOgImage?: string | null
    canonical?: string
    noIndex?: boolean
  }
  locale?: string
}

export default function Metatags({
  meta,
  locale,
}: MetatagsProps): ReactElement {
  return (
    <Helmet htmlAttributes={{ lang: locale }}>
      {meta?.metaTitle && <title>{meta.metaTitle}</title>}

      {meta?.metaTitle && <meta property="og:title" content={meta.metaTitle} />}

      {meta?.metaDescription && (
        <meta name="description" content={meta.metaDescription} />
      )}

      {meta?.metaDescription && (
        <meta property="og:description" content={meta.metaDescription} />
      )}

      <meta name="twitter:card" content="summary" />

      {meta?.metaOgImage && (
        <meta property="og:image" content={meta.metaOgImage} />
      )}

      {meta?.metaOgImage && (
        <meta property="og:image:secure" content={meta.metaOgImage} />
      )}

      {meta?.metaOgImage && (
        <meta name="twitter:image" content={meta.metaOgImage} />
      )}

      {meta?.canonical && <link rel="canonical" href={meta.canonical} />}

      {meta?.noIndex && <meta name="robots" content="noindex" />}
    </Helmet>
  )
}
