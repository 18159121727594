import React, { ReactElement } from 'react'

import { Box } from '@mui/material'
import theme from '@config/theme'

interface IBurger {
  active: boolean
}

export default function Burger({ active }: IBurger): ReactElement {
  return (
    <Box
      data-testid="burger"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'stretch',
        height: theme.spacing(4.5),
        width: theme.spacing(5),
      }}
    >
      <Box
        sx={{
          backgroundColor: theme.palette.text.primary,
          height: '2px',
          width: '100%',
          transition: '0.2s',
          ...(active === true && {
            width: '108%',
            transform: 'translateY(6px) translateX(0px) rotate(45deg)',
          }),
        }}
      ></Box>
      <Box
        sx={{
          backgroundColor: theme.palette.text.primary,
          height: '2px',
          width: '100%',
          transition: '0.2s',
          ...(active === true && {
            width: '10%',
            transform: 'translateX(10px)',
          }),
        }}
      ></Box>
      <Box
        sx={{
          backgroundColor: theme.palette.text.primary,
          height: '2px',
          width: '100%',
          transition: '0.2s',
          ...(active === true && {
            width: '108%',
            transform: 'translateY(-6px) translateX(0px) rotate(-45deg)',
          }),
        }}
      ></Box>
    </Box>
  )
}
