import { Components, Theme } from '@mui/material'

import {
  poppinsRegular,
  poppinsMedium,
  poppinsSemibold,
} from '../../definitions/fontFamily'

export const CssBaselineOverrides = (
  theme: Theme
): Components['MuiCssBaseline'] => ({
  styleOverrides: {
    '@font-face': poppinsRegular,
    fallbacks: [
      {
        '@font-face': poppinsMedium,
      },
      {
        '@font-face': poppinsSemibold,
      },
    ],
    a: {
      color: 'currentColor',
      fontWeight: 500,
    },
    strong: {
      fontWeight: 500,
    },
  },
})
